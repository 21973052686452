import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import i18n from '@shared/i18n/vue-i18n'

export default function useLogout(options = {}) {
  const store = useStore()
  const router = useRouter()

  function logout() {
    store.commit('loading/SET_TEXT', i18n.global.t('ttmt.auth.logout.logging_out'))
    store.commit('loading/ENABLE')
    store.dispatch('auth/signOutFromApi')
      .then(async () => {
        await router.push({ name: options.auth_route_name ?? 'login' })
      })
      .finally(() => {
        store.commit('loading/RESET')
      })
  }

  return {
    logout,
  }
}
