<template>
  <div class="flex flex-col justify-between h-full">
    <ul class="pb-4 space-y-1 list-none w-full flex-grow">
      <menu-item
        :label="t('ttmt.settings.items.profile')"
        :icon-props="{ library: 'coolicon', name: 'user' }"
        @clicked="userProfile"
      />

      <menu-item
        :label="t('ttmt.settings.items.privacy_setting')"
        :icon-props="{ library: 'coolicon', name: 'file_blank_outline' }"
        @clicked="privacySetting"
      />

      <menu-item
        :label="t('ttmt.settings.items.geoloc_settings')"
        :icon-props="{ library: 'coolicon', name: 'location_outline' }"
        @clicked="geolocSettings"
      />

      <menu-item
        :label="t('ttmt.settings.items.notification_settings')"
        :icon-props="{ library: 'coolicon', name: 'notification_outline' }"
        @clicked="notificationSetting"
      />

      <menu-item
        :label="t('ttmt.settings.items.signOut')"
        :icon-props="{ library: 'coolicon', name: 'log_out' }"
        @clicked="logout"
      />
    </ul>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import useLogout from '@shared/hooks/auth/logout'
import MenuItem from '@app/components/features/menu/MenuItem.vue'

defineEmits([
  'closed',
])

const { logout } = useLogout({
  auth_route_name: 'login',
})

const router = useRouter()
const { t } = useI18n()

// ---------- ROUTES ----------

async function userProfile() {
  await router.push({ name: 'show-profile' })
}
async function privacySetting() {
  await router.push({ name: 'edit-privacy-settings' })
}
async function geolocSettings() {
  await router.push({ name: 'edit-geoloc-settings' })
}
async function notificationSetting() {
  await router.push({ name: 'edit-notification-settings' })
}
</script>
